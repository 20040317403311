<template>
  <div>
    <vue-modaltor :visible="this.open" @hide="this.open=false">
      <template #header>
        <v-row class="mx-sm-8 mx-2">
          <v-col cols="11">
            <h1>Histórico</h1>
          </v-col>
          <v-col cols="1">
            <button @click="closePop()"><i class="close-icon">X</i></button>
          </v-col>
        </v-row>
      </template>
      <template #body>
        <v-row class="mx-sm-8 mx-2">
          <v-col cols="4">
          </v-col>
          <v-col cols="8">
            <Timeline
              :timeline-items="timelineItems"
              :message-when-no-items="messageWhenNoItems"
              order="asc"/>
          </v-col>
        </v-row>
      </template>
    </vue-modaltor>
  </div>
</template>

<script>
import Timeline from "timeline-vuejs";

export default {
  name:"TimelineTender",
  components: {
    Timeline
  },
  props: {
    open: Boolean,
    dataUsers: Array
  },
  data: () => (
    {
    messageWhenNoItems: 'Sin registros',
    timelineItems: []
  }),
  created() {
    if(this.dataUsers){
      this.buildData();
    }
  },
  methods: {
    closePop() {
      this.$emit('update', false);
    },
    buildData(){
      
      this.dataUsers.forEach(data => {

        const timeCreated = new Date(data.createdAt);
        const created = {
          from: timeCreated,
          title: 'Creado ' + data.nombreFormato,
          description: timeCreated.getHours() + ':' + timeCreated.getMinutes(),
          showDayAndMonth: true
        };
        this.timelineItems.push(created);

        if(data.usuarioRevisionAt) {
          const timeRevision = new Date(data.usuarioRevisionAt);
          const revision = {
            from: timeRevision,
            title: 'A revisión ' + data.userRevision.nombreCompleto,
            description: data.nombreFormato + ' - ' + timeRevision.getHours() + ':' + timeRevision.getMinutes(),
            showDayAndMonth: true
          };
          this.timelineItems.push(revision);
        }
        if(data.usuarioCompletoAt) {
          const timeCompleto = new Date(data.usuarioCompletoAt);
          const completo = {
            from: timeCompleto,
            title: 'Validado ' + data.userCompleto.nombreCompleto,
            description: data.nombreFormato + ' - ' + timeCompleto.getHours() + ':' + timeCompleto.getMinutes(),
            showDayAndMonth: true
          };
          this.timelineItems.push(completo);
        }
      });
    }
  }
}
</script>

<style scoped>
.close-icon {
  font-size: 25px;
  font-weight: bold;
}
</style>
