<template>
  <div>

    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10">Licitaciones</h1>
        <hr class="">
      </v-col>
      <v-col cols="3" sm="3" md="3" class="mt-10  ">
        <v-text-field v-model="searchData" label="Buscar"></v-text-field>
      </v-col>
      <v-col cols="6" class="mt-10  ">
      </v-col>

      <v-col cols="3" class="mt-10 ">
        <v-btn color="primary" :to="{ name: 'CreateTenders' }" style="width: 100%">
          Crear nueva
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="licitaciones"
            :items-per-page="5"
            class="elevation-2"
        >
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <div class="mr-2 card-icons-table">
                <router-link :to="{ name: 'TendersDetails', params: { id: item.id } }">
                  <v-icon medium color="black">mdi-magnify</v-icon>
                </router-link>
              </div>
              <div class="mr-2 card-icons-table">
                <router-link :to="{ name: 'DetailsCards', params: { id: item.id } }">
                  <v-icon medium color="black">mdi-dialpad</v-icon>
                </router-link>
              </div>
              <div class="mr-2 card-icons-table">
                <v-btn  @click="showTimeline(item.id)" type="button" color="white" class="btnHistoric" >
                  <v-icon medium color="black">mdi-calendar</v-icon>
                </v-btn>
              </div>
              <div v-if="disabledTimeline">
                <TimelineTender
                  :open="openTime"
                  @update="closeTimeline($event)"
                  :dataUsers="dataTender"
                  />
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import TimelineTender from "../../components/TimelineTender"

export default {
  name: "Tenders",
  components: {
      TimelineTender
  },
  data() {
    return {
      headers: [
        {text: 'id', value: 'id'},
        {text: 'Nombre', value: 'nombre'},
        {text: 'Codigo', value: 'claveLicitacion'},
        {text: 'Zona', value: 'tipoLicitacion'},
        {text: 'Tipo', value: 'tipo'},
        {text: 'Acciones', value: 'action', sortable: false},
      ],
      licitaciones: [],
      searchData: null,
      disabledTimeline: false,
      openTime: false,
      dataTender: [],
    };

  },
  async created() {
    await this.findAll();

  },
  methods: {
    ...mapActions('tenders', ['findAllTenders']),
    ...mapActions('statusTender', ['getEstatusLicitacion']),
    async findAll() {
      const response = await this.findAllTenders();
      if (response.length > 0) {
        this.licitaciones = response;
        return
      }
      this.licitaciones = [];
    },
    async showTimeline(tenderId) {
      const estatus = await this.getEstatusLicitacion({tenderId: tenderId});
      if(estatus) {
        this.dataTender = estatus;
        this.openTime = true;
        this.disabledTimeline= true;
      }
    },
    closeTimeline(openValue) {
      this.openTime = openValue;
    },
    download(item) {
    },
    search(item) {
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.btnHistoric {
  height: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
}
</style>
